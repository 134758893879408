import React, { useEffect, useRef, useState } from "react";
import "./Moveablecomp.css";

function Movablecomp({
  img,
  changeWidth,
  changeX,
  changeY,
  changeHeight,
  canvasTop,
  canvasBottom,
  canvasLeft,
  canvasRight,
}) {
  const [width, setWidth] = useState(0);
  const [height, setheight] = useState(0);
  const [setX, setSetX] = useState(0);
  const [setY, setSetY] = useState(0);
  //userefs
  const elRef = useRef(null);
  const [move, setMove] = useState(null);
  useEffect(() => {
    const move = new window.Moveable(document.querySelector("#content"), {
      target: document.querySelectorAll("#movablecomp-root"),
      draggable: true,
      resizable: true,
      rotatable: false,
    });

    setMove(move);

    move.on("drag", ({ target, transform }) => {
      target.style.transform = transform;
    });

    move.on("resize", ({ target, width, height }) => {
      target.style.width = width + "px";
      target.style.height = height + "px";
    });
    move.on("rotate", ({ target, transform }) => {
      target.style.transform = transform;
    });
  }, []);

  function setCordinates() {
    setWidth(elRef.current.getBoundingClientRect().width);
    setSetX(elRef.current.getBoundingClientRect().left);
    setSetY(elRef.current.getBoundingClientRect().top);
    setheight(elRef.current.getBoundingClientRect().height);
    changeWidth(width);
    changeHeight(height);
    changeX(setX);
    changeY(setY);
  }
  function removeSign() {
    move.destroy();
  }

  return (
    <div
      id="movablecomp-root"
      ref={elRef}
      onDoubleClick={() => console.log(width, setX, setY)}
      onMouseDown={setCordinates}
      onMouseMove={setCordinates}
      onMouseClick={setCordinates}
    >
      <img src={img} alt="" />
      <i class="fas fa-times-circle" onClick={removeSign}></i>
    </div>
  );
}

export default Movablecomp;
