import React from "react";

import SignaturePad from "react-signature-canvas";
import { useRef, useState } from "react";
import Model from "react-modal";
import "./Model.css";
import Axios from "axios";

import PdfCanvas from "./PdfCanvas";
import Movablecomp from "./Movablecomp";
import MovableTest from "./MovableTest";

Model.setAppElement("#root");

function Modal() {
  const [name, setName] = useState("");
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [color, setColor] = useState("black");

  const [fileProp, setFileProp] = useState();

  //States for multiple moveable signs
  const [moveableList, setMoveableList] = useState([]);

  //file upload multer
  const send = () => {
    const data = new FormData();
    data.append("name", name);
    data.append("file", file);

    Axios.post("https://esign-api.efito.xyz/uploadFile", data)
      .then((res) => {
        setFileProp(file);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const customStyles = {
    content: {
      width: 600,
      height: 225,
      overflow: "hidden",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  let signPad = useRef({
    minDistance: 0,
    throttle: 9,
    velocityFilterWeight: 0.7,
  });

  function clear() {
    signPad.current.clear();
  }

  function save() {
    setImage(signPad.current.getTrimmedCanvas().toDataURL("image/png"));
    let base64Png = signPad.current.getTrimmedCanvas().toDataURL("image/png");

    setmodalIsOpen(false);
  }

  function handleInputChange(event) {
    const file = event.target.files[0];
    send(event);

    setFile(file);
  }

  const handleAddInput = () => {
    setMoveableList([...moveableList, "inputName"]);
    console.log(moveableList);
  };

  return (
    <div>
      <div className="modal-top-btn-container">
        <button
          className="modal-add-signature"
          onClick={() => setmodalIsOpen(true)}
        >
          Add Signature
        </button>
        <div className="modal-add-signature-lower">
          <div>
            <form action="#">
              <input type="file" id="file" onChange={handleInputChange} />
            </form>
          </div>
          <button
            className="modal-add-signature"
            onClick={() => {
              send();
              setFile(file);
              console.log("file");
            }}
          >
            Add File
          </button>
        </div>
      </div>

      <br />

      {/* <DragComp1 img={image} /> */}
      {/* <DragComp2 /> */}
      {/* <DragComp img={image} /> */}
      <PdfCanvas img={image} pdf={fileProp} />
      <Model
        isOpen={modalIsOpen}
        onRequestClose={() => setmodalIsOpen(false)}
        style={customStyles}
      >
        <div className="modal-container">
          <div className="modal-container-header">
            <div>
              <h3>Sign Document</h3>
            </div>
            <div>
              <button onClick={clear}>Clear</button>
            </div>
          </div>
          <div className="modal-container-signpad">
            <SignaturePad
              ref={signPad}
              canvasProps={{ className: "sigpad" }}
              penColor={color}
            />
          </div>

          <button className="modal-savebtn" onClick={save}>
            Add Signature
          </button>

          <button
            className="modal-closebtn"
            onClick={() => {
              setmodalIsOpen(false);
              //console.log(image);
            }}
          >
            Close
          </button>
        </div>
      </Model>
    </div>
  );
}

export default Modal;
