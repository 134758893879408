import React, { useState, useRef, useEffect } from "react";
import jsPDF from "jspdf";
import DragComp1 from "./DragComp1";
import "./PdfCanvas.css";
import Moveavlecomp from "./Movablecomp";

import { Document, Page } from "react-pdf";

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PdfCanvas({ img, pdf }) {
  //states
  const [numPages, setNumPages] = useState(null);
  const [numPagesMod, setNumPagesMod] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const [width, setWidth] = useState(0);
  const [height, setheight] = useState(0);
  const [setX, setSetX] = useState(0);
  const [setY, setSetY] = useState(0);
  //canvas dimensions
  const [canvasX, setCanvasX] = useState(0);
  const [canvasY, setCanvasY] = useState(0);
  const [canvasBottom, setCanvasBottom] = useState(0);
  const [canvasRight, setCanvasRight] = useState(0);

  //sign current page number
  const [signPage, setSignPage] = useState();

  //height of single pdf page
  const [onePageHeight, setOnePageHeight] = useState();

  //userefs
  const elRef = useRef(null);
  const elRef1 = useRef(null);

  const [movableList, setMovableList] = useState([]);

  useEffect(() => {
    setCanvasX(elRef.current.getBoundingClientRect().left);
    setCanvasY(elRef.current.getBoundingClientRect().top);
    setCanvasRight(elRef.current.getBoundingClientRect().bottom);
    setCanvasBottom(elRef.current.getBoundingClientRect().right);
    setPdfPage();
  }, [setX]);
  useEffect(() => {
    setCanvasX(elRef.current.getBoundingClientRect().left);
    setCanvasY(elRef.current.getBoundingClientRect().top);
    setCanvasRight(elRef.current.getBoundingClientRect().bottom);
    setCanvasBottom(elRef.current.getBoundingClientRect().right);
    setPdfPage();
  }, [pdf]);

  //json sign details
  var arr = [
    {
      text: "",
      x: setX - canvasX,
      y: setY - canvasY,
      imgz: img,
      width: width,
      height: height,
      page: signPage,
      pageHeight: onePageHeight,
    },
  ];

  //post request requirements
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      images: arr,
      pages: numPages,
    }),
  };

  const fetchItemsPost = async () => {
    const response = await fetch(
      `https://esign-api.efito.xyz/getdata`,
      requestOptions
    );
    const data = await response;
    window.open("https://esign-api.efito.xyz/upload");
  };

  function onDocumentLoadSuccess({ numPages }) {
    console.log(numPages);
    setNumPages(numPages);
    setNumPagesMod(numPages - 1);
    setIsPageLoaded(true);
    setCordinates();
  }

  //old pdf generate method. Not using anymore coz generated pdf is in image form
  function generatePdf() {
    console.log(img);
    var doc = new jsPDF("p", "pt", "a4");
    doc.html(document.querySelector("#content"), {
      callback: function (pdf) {
        var pageCount = doc.internal.getNumberOfPages();
        /* pdf.deletePage(pageCount); */
        pdf.save("myPdf.pdf");
      },
    });
  }

  function setCordinates() {
    setCanvasX(elRef.current.getBoundingClientRect().left);
    setCanvasY(elRef.current.getBoundingClientRect().top);
    setCanvasRight(elRef.current.getBoundingClientRect().bottom);
    setCanvasBottom(elRef.current.getBoundingClientRect().right);
    console.log("Current page" + signPage);
  }

  //set pdf page
  function setPdfPage() {
    let fullHeight = setY - canvasY;
    let pageHeight = elRef.current.getBoundingClientRect().height / numPages;
    setOnePageHeight(pageHeight);
    let page = Math.ceil(fullHeight / pageHeight);
    setSignPage(page);
  }

  return (
    <div>
      <div className="pdfcanvas-container">
        <div id="content" ref={elRef} onDoubleClick={setCordinates}>
          <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
            {[...Array(numPages)].map((i, index) => (
              <Page
                key={index}
                pageNumber={index + 1}
                renderTextLayer={false}
              />
            ))}
            <Moveavlecomp
              className="pdfcanvas-moveablecomp"
              img={img}
              changeWidth={(width) => setWidth(width)}
              changeHeight={(height) => setheight(height)}
              changeX={(x) => setSetX(x)}
              changeY={(y) => setSetY(y)}
              canvasTop={canvasY}
              canvasLeft={canvasX}
              canvasRight={canvasRight}
              canvasBottom={canvasBottom}
            />
            {movableList.map((index) => {
              return (
                <Moveavlecomp
                  className="pdfcanvas-moveablecomp"
                  img={img}
                  changeWidth={(width) => setWidth(width)}
                  changeHeight={(height) => setheight(height)}
                  changeX={(x) => setSetX(x)}
                  changeY={(y) => setSetY(y)}
                  canvasTop={canvasY}
                  canvasLeft={canvasX}
                  canvasRight={canvasRight}
                  canvasBottom={canvasBottom}
                />
              );
            })}
          </Document>
        </div>
      </div>
      <div className="pdfcanvas-container">
        {pdf && (
          <button className="modal-add-signature" onClick={fetchItemsPost} type="primary">
            Generate PDF
          </button>
        )}
      </div>
    </div>
  );
}

export default PdfCanvas;
